import React from 'react';
import { ArrowLeft, ArrowRight } from '../../../Svg';
import styled from 'styled-components';
import {
  bodyBold,
  bodyDefault,
  Color,
  columnSpacing,
  displayNoneByBreakpoint,
  flex,
  h2Desktop,
  h2Mobile,
  marginLeft,
  marginTop,
  maxWidth,
  paddingTop,
  typographyByBreakpoint,
} from '../../../../styles';
import { Link as BaseLink } from 'gatsby';

const CardPast = styled.div`
  ${marginLeft(0, 0, 107)};
  position: relative;
  z-index: 1;
`;

const PastCardHeader = styled.div`
  border-top: 1px solid ${Color.SEAFOAM_GREEN};
  ${paddingTop(32, 32, 49)};
`;

const Link = styled(BaseLink)`
  ${flex('flex-start', 'center')}
  ${columnSpacing(24, 24, 16)};

  span {
    text-decoration: underline;
    ${bodyBold};
    color: ${Color.BLACK};
    ${displayNoneByBreakpoint('block', true, true, false, true)};
  }
`;

const PastEventDate = styled.h2`
  ${bodyDefault}
`;

const PastCardContainer = styled.div`
  ${flex('space-between', 'center')};
  ${marginTop(8, 8, 16)};
  ${columnSpacing(24, 24, 32)};
`;

const PastEventTitle = styled.h3`
  ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
  ${maxWidth('unset', 'unset', '79.5rem')};
`;

export const PastCard = ({ date, title, slug }: { date: string; title: string; slug: string; }) => (
  <CardPast>
    <PastCardHeader>
    <PastEventDate>{date}</PastEventDate>
    </PastCardHeader>
    <PastCardContainer>
      <PastEventTitle>{title}</PastEventTitle>
      {!!slug && (
        <Link to={slug.current}>
          <span>View Event Highlights</span>
          {ArrowRight}
        </Link>
      )}
    </PastCardContainer>
  </CardPast>
);
