import React from 'react';
import {graphql} from "gatsby";
import WebsiteDefaultPage from '../../components/Page/Base';
import { PastEventsPage } from '../../components/PageSpecific/community/pastevents';
import { JoinSection } from '../../components/SharedSections';
import { Color } from '../../styles';
import { LIGHT_DARK } from '../../constants';

const PastEvents = ({data}) => {
  return (
    <WebsiteDefaultPage bodyBackground={Color.OFF_WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <PastEventsPage data = {data}/>
      <JoinSection />
    </WebsiteDefaultPage>
  );
};

export default PastEvents;

export const query = graphql`
query EventsQuery {
 pastEvents: allSanityPastEvents(sort: {fields: _createdAt, order: DESC}) {
    edges {
      node {
        id
        date
        day
        title
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(fit: FILL, placeholder: BLURRED)
            url
          }
        }
      }
    }
  }
  
  upcomingEvents: allSanityUpcomingEvents(sort: {fields: _createdAt, order: DESC}) {
      edges {
        node {
          id
          date
          day
          eventLink
          title
          desc
          mainImage {
            asset {
              gatsbyImageData(fit: FILL, placeholder: BLURRED)
              url
            }
          }
        }
      }
    }
  }
`